@charset "UTF-8";

@font-face {
  font-family: "healent";
  src:url("fonts/healent.eot");
  src:url("fonts/healent.eot?#iefix") format("embedded-opentype"),
    url("fonts/healent.woff") format("woff"),
    url("fonts/healent.ttf") format("truetype"),
    url("fonts/healent.svg#healent") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "healent" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="healent-"]:before,
[class*=" healent-"]:before {
  font-family: "healent" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.healent-alcoholic-drinks:before {
  content: "\61";
}
.healent-android-delete:before {
  content: "\62";
}
.healent-avatar-solid:before {
  content: "\63";
}
.healent-bell:before {
  content: "\64";
}
.healent-blood-pressure:before {
  content: "\65";
}
.healent-blood-sugar-1:before {
  content: "\66";
}
.healent-blood-sugar:before {
  content: "\67";
}
.healent-bxs-dashboard:before {
  content: "\68";
}
.healent-bxs-time-five:before {
  content: "\69";
}
.healent-calendar-fill:before {
  content: "\6a";
}
.healent-chat:before {
  content: "\6b";
}
.healent-cigarettes:before {
  content: "\6c";
}
.healent-collapse:before {
  content: "\6d";
}
.healent-coping-with-anxiety:before {
  content: "\6e";
}
.healent-dashboard:before {
  content: "\6f";
}
.healent-document:before {
  content: "\70";
}
.healent-edit:before {
  content: "\71";
}
.healent-edit-solid:before {
  content: "\72";
}
.healent-emailalt:before {
  content: "\73";
}
.healent-foot-temperature:before {
  content: "\74";
}
.healent-gear-fill:before {
  content: "\75";
}
.healent-logout:before {
  content: "\76";
}
.healent-group-685:before {
  content: "\77";
}
.healent-hours-of-sleep:before {
  content: "\78";
}
.healent-logout-1:before {
  content: "\79";
}
.healent-menu:before {
  content: "\7a";
}
.healent-nutrition:before {
  content: "\41";
}
.healent-pain-score:before {
  content: "\42";
}
.healent-patients:before {
  content: "\43";
}
.healent-phone:before {
  content: "\44";
}
.healent-physical-therapy:before {
  content: "\45";
}
.healent-plus:before {
  content: "\46";
}
.healent-pulse-rate:before {
  content: "\47";
}
.healent-quality-of-sleep:before {
  content: "\48";
}
.healent-ref:before {
  content: "\49";
}
.healent-reports:before {
  content: "\4a";
}
.healent-search:before {
  content: "\4b";
}
.healent-settings:before {
  content: "\4c";
}
.healent-spo2:before {
  content: "\4d";
}
.healent-steps-count:before {
  content: "\4e";
}
.healent-water-intake:before {
  content: "\4f";
}
.healent-activity:before {
  content: "\50";
}
.healent-weight:before {
  content: "\51";
}
.healent-hospital:before {
  content: "\30";
}
.healent-path-2515:before {
  content: "\31";
}
.healent-path-2516:before {
  content: "\32";
}
.healent-ios-water:before {
  content: "\33";
}
.healent-heartbeat:before {
  content: "\34";
}
.healent-path-2184:before {
  content: "\35";
}
.healent-path-2182:before {
  content: "\36";
}
.healent-path-2183:before {
  content: "\37";
}
.healent-path-2185:before {
  content: "\38";
}
.healent-path-2186:before {
  content: "\39";
}
.healent-path-2187:before {
  content: "\21";
}
.healent-path-2188:before {
  content: "\22";
}
.healent-path-2189:before {
  content: "\23";
}
.healent-local-hotel:before {
  content: "\24";
}
.healent-emojisleep:before {
  content: "\25";
}
.healent-weightscale:before {
  content: "\26";
}
.healent-alcohol-shop-11:before {
  content: "\27";
}
.healent-path-2178:before {
  content: "\28";
}
.healent-path-2519:before {
  content: "\29";
}
.healent-line-chart:before {
  content: "\2a";
}
.healent-arrow-ios-forward-fill:before {
  content: "\2b";
}
.healent-group-2652:before {
  content: "\2c";
}
.healent-partner:before {
  content: "\2d";
}
.healent-group-2658:before {
  content: "\2e";
}
.healent-group-2659:before {
  content: "\2f";
}
.healent-dotlist:before {
  content: "\3a";
}
.healent-tasks-solid:before {
  content: "\3b";
}
.healent-group-2659-1:before {
  content: "\3c";
}
.healent-group-2659-2:before {
  content: "\3d";
}
.healent-group-2660:before {
  content: "\3e";
}
.healent-med-circle:before {
  content: "\3f";
}
.healent-security:before {
  content: "\40";
}
.healent-notifications-circle:before {
  content: "\5b";
}
.healent-calendar-circle:before {
  content: "\5d";
}
.healent-assessments-circle:before {
  content: "\5e";
}
.healent-chartcheck-circle:before {
  content: "\5f";
}
.healent-nurse:before {
  content: "\7c";
}
.healent-time-circle:before {
  content: "\5c";
}
.healent-back:before {
  content: "\53";
}
.healent-time:before {
  content: "\54";
}
.healent-tag:before {
  content: "\55";
}
.healent-key-sharp:before {
  content: "\56";
}
.healent-ios-checkmark-circle:before {
  content: "\57";
}
.healent-path-2512:before {
  content: "\58";
}
.healent-exercise-1:before {
  content: "\59";
}
.healent-clinic:before {
  content: "\5a";
}
.healent-phone-1:before {
  content: "\52";
}
.healent-patients-log:before {
  content: "\60";
}
.healent-icon:before {
  content: "\7b";
}
.healent-icon-1:before {
  content: "\7d";
}
.healent-icon-2:before {
  content: "\7e";
}
.healent-icon-3:before {
  content: "\e000";
}
.healent-icon-material-bubble-chart:before {
  content: "\e001";
}
.healent-path-3282:before {
  content: "\e002";
}
.healent-icon-metro-profile:before {
  content: "\e003";
}
.healent-low-data:before {
  content: "\e004";
}
.healent-missing-137:before {
  content: "\e005";
}
.healent-no-call-since-21-days:before {
  content: "\e006";
}
.healent-no-chart-check:before {
  content: "\e007";
}
.healent-no-issues:before {
  content: "\e008";
}
.healent-out-of-range-values:before {
  content: "\e009";
}
.healent-with-alerts:before {
  content: "\e00a";
}
.healent-uncompleted-notes:before {
  content: "\e00b";
}
.healent-time-1:before {
  content: "\e00c";
}
.healent-battery-solid:before {
  content: "\e00d";
}
.healent-group-2730:before {
  content: "\e00e";
}
.healent-rectangle-2531:before {
  content: "\e00f";
}
.healent-path-2574:before {
  content: "\e010";
}
.healent-path-2573:before {
  content: "\e011";
}
.healent-path-2572:before {
  content: "\e012";
}
.healent-path-2571:before {
  content: "\e013";
}
.healent-help-circle-outline:before {
  content: "\e014";
}
.healent-icon-4:before {
  content: "\e015";
}
.healent-path-2569:before {
  content: "\e016";
}
.healent-group-4044:before {
  content: "\e017";
}
.healent-close-circle:before {
  content: "\e018";
}
.healent-pause-circle-fill:before {
  content: "\e019";
}
.healent-play-circle-fill:before {
  content: "\e01a";
}
.healent-path-2584:before {
  content: "\e01b";
}
.healent-bxl-instagram-alt:before {
  content: "\e01c";
}
.healent-facebook-rect:before {
  content: "\e01d";
}
.healent-youtube:before {
  content: "\e01e";
}
.healent-temp-svg:before {
  content: "\e01f";
}
.healent-squaretwitter:before {
  content: "\e020";
}
.healent-linkedin-square:before {
  content: "\e021";
}
.healent-group-2763:before {
  content: "\e022";
}
.healent-group-2764:before {
  content: "\e023";
}
.healent-group-2765:before {
  content: "\e024";
}
.healent-path-2610:before {
  content: "\e025";
}
.healent-path-2610-1:before {
  content: "\e026";
}
.healent-down:before {
  content: "\e027";
}
.healent-up:before {
  content: "\e028";
}
.healent-weightscale-1:before {
  content: "\e029";
}
.healent-path-1894:before {
  content: "\e02a";
}
.healent-group-1456:before {
  content: "\e02b";
}
.healent-assignment:before {
  content: "\e02c";
}
.healent-icon-material-explore:before {
  content: "\e02d";
}
.healent-bar-chart-fill:before {
  content: "\e02e";
}
.healent-path-2613:before {
  content: "\e02f";
}
.healent-group-4359:before {
  content: "\e030";
}
.healent-sad:before {
  content: "\e031";
}
.healent-bxs-happy-alt:before {
  content: "\e032";
}
.healent-icon-awesome-calendar-plus:before {
  content: "\e033";
}
.healent-path-3320:before {
  content: "\e034";
}
.healent-path-3321:before {
  content: "\e035";
}
.healent-moon-fill:before {
  content: "\e036";
}
.healent-temp-svg-1:before {
  content: "\e037";
}
.healent-note-edit-solid:before {
  content: "\e038";
}
.healent-subtraction-6:before {
  content: "\e039";
}
.healent-path-2940:before {
  content: "\e03a";
}
.healent-path-3321-1:before {
  content: "\e03b";
}
.healent-path-3322:before {
  content: "\e03c";
}
.healent-archive:before {
  content: "\e03d";
}
.healent-path-3464:before {
  content: "\e03e";
}
.healent-download:before {
  content: "\e03f";
}
.healent-path-3432:before {
  content: "\e040";
}
.healent-ellipse-1314:before {
  content: "\e041";
}
.healent-path-3772:before {
  content: "\e042";
}
.healent-subtraction-29:before {
  content: "\e043";
}
.healent-path-4024:before {
  content: "\e044";
}
.healent-3:before {
  content: "\e045";
}
.healent-form-input-icon-1:before {
  content: "\e046";
}
.healent-form-input-icon-2:before {
  content: "\e047";
}
.healent-form-input-icon-3:before {
  content: "\e048";
}
.healent-form-input-icon-4:before {
  content: "\e049";
}
.healent-form-input-icon-5:before {
  content: "\e04a";
}
.healent-form-input-icon-6:before {
  content: "\e04b";
}
.healent-form-input-icon-7:before {
  content: "\e04c";
}
.healent-form-input-icon-8:before {
  content: "\e04d";
}
.healent-form-input-icon-9:before {
  content: "\e04e";
}
.healent-form-input-icon-10:before {
  content: "\e04f";
}
.healent-form-input-icon-11:before {
  content: "\e050";
}
.healent-form-input-icon-12:before {
  content: "\e051";
}
.healent-form-input-icon-13:before {
  content: "\e052";
}
.healent-form-input-icon-14:before {
  content: "\e053";
}
.healent-path-4028:before {
  content: "\e054";
}
.healent-form-input-icon-19:before {
  content: "\e055";
}
.healent-form-input-icon-20:before {
  content: "\e056";
}
.healent-form-input-icon-21:before {
  content: "\e057";
}
.healent-form-input-icon-22:before {
  content: "\e058";
}
.healent-form-input-icon-23:before {
  content: "\e059";
}
.healent-form-input-icon-24:before {
  content: "\e05a";
}
.healent-form-input-icon-25:before {
  content: "\e05b";
}
.healent-form-input-icon-26:before {
  content: "\e05c";
}
.healent-form-input-icon-27:before {
  content: "\e05d";
}
.healent-form-input-icon-28:before {
  content: "\e05e";
}
.healent-form-input-icon-29:before {
  content: "\e05f";
}
.healent-form-input-icon-30:before {
  content: "\e060";
}
.healent-form-input-icon-31:before {
  content: "\e061";
}
.healent-form-input-icon-41:before {
  content: "\e062";
}
.healent-form-input-icon-40:before {
  content: "\e063";
}
.healent-form-input-icon-39:before {
  content: "\e064";
}
.healent-form-input-icon-38:before {
  content: "\e065";
}
.healent-form-input-icon-37:before {
  content: "\e066";
}
.healent-form-input-icon-36:before {
  content: "\e067";
}
.healent-form-input-icon-34:before {
  content: "\e069";
}
.healent-form-input-icon-33:before {
  content: "\e06a";
}
.healent-form-input-icon-32:before {
  content: "\e06b";
}
.healent-form-input-icon-35:before {
  content: "\e068";
}
.healent-path-104:before {
  content: "\e06c";
}
.healent-path-105:before {
  content: "\e06d";
}
.healent-form-input-icon-42:before {
  content: "\e06e";
}
.healent-form-input-icon-43:before {
  content: "\e06f";
}
.healent-form-input-icon-44:before {
  content: "\e070";
}
.healent-form-input-icon-45:before {
  content: "\e071";
}
.healent-form-input-icon-46:before {
  content: "\e072";
}
.healent-path-4029:before {
  content: "\e073";
}
.healent-path-4030:before {
  content: "\e074";
}
.healent-path-4034:before {
  content: "\e075";
}
.healent-path-4035:before {
  content: "\e076";
}
.healent-path-4039:before {
  content: "\e077";
}
.healent-path-4040:before {
  content: "\e078";
}
.healent-form-input-icon-47:before {
  content: "\e079";
}
.healent-path-4045:before {
  content: "\e07a";
}
.healent-path-4041:before {
  content: "\e07b";
}
.healent-group-4509:before {
  content: "\e07c";
}
.healent-union-5:before {
  content: "\e07d";
}
.healent-path-4051:before {
  content: "\e07e";
}
.healent-path-4052:before {
  content: "\e07f";
}
.healent-path-4053:before {
  content: "\e080";
}
.healent-path-4054:before {
  content: "\e081";
}
.healent-layer-2:before {
  content: "\e082";
}
.healent-path-2683:before {
  content: "\e083";
}
.healent-path-2682:before {
  content: "\e084";
}
.healent-path-4061-1:before {
  content: "\e089";
}
.healent-path-4062-1:before {
  content: "\e08a";
}
.healent-path-4070:before {
  content: "\e085";
}
.healent-path-4070-1:before {
  content: "\e086";
}
.healent-group-2843:before {
  content: "\e087";
}
.healent-mob:before {
  content: "\e088";
}
.healent-sms:before {
  content: "\e08b";
}
.healent-path-4078:before {
  content: "\e08c";
}
.healent-form-input-icon-15:before {
  content: "\e08d";
}
.healent-form-input-icon-24-1:before {
  content: "\e08e";
}
.healent-form-input-icon-64:before {
  content: "\e08f";
}
.healent-path-4021:before {
  content: "\e090";
}
.healent-path-4076:before {
  content: "\e091";
}
.healent-body-to:before {
  content: "\e092";
}
.healent-image-to:before {
  content: "\e093";
}
.healent-path-4065:before {
  content: "\e094";
}
.healent-out-of-range-values-1:before {
  content: "\e095";
}
.healent-q-1:before {
  content: "\e096";
}
.healent-q-2:before {
  content: "\e097";
}
.healent-q-6:before {
  content: "\e098";
}
.healent-q-7:before {
  content: "\e099";
}
.healent-q-8:before {
  content: "\e09a";
}
.healent-q-9:before {
  content: "\e09b";
}
.healent-q-5:before {
  content: "\e09c";
}
.healent-q-4:before {
  content: "\e09d";
}
.healent-form-input-icon-48:before {
  content: "\e09e";
}
.healent-form-input-icon-55:before {
  content: "\e09f";
}
.healent-form-input-icon-56:before {
  content: "\e0a0";
}
.healent-form-input-icon-57:before {
  content: "\e0a1";
}
.healent-form-input-icon-58:before {
  content: "\e0a2";
}
.healent-form-input-icon-59:before {
  content: "\e0a3";
}
.healent-form-input-icon-60:before {
  content: "\e0a4";
}
.healent-form-input-icon-61:before {
  content: "\e0a5";
}
.healent-form-input-icon-62:before {
  content: "\e0a6";
}
.healent-form-input-icon-63:before {
  content: "\e0a7";
}
.healent-form-input-icon-49:before {
  content: "\e0a8";
}
.healent-form-input-icon-50:before {
  content: "\e0a9";
}
.healent-form-input-icon-51:before {
  content: "\e0aa";
}
.healent-form-input-icon-52:before {
  content: "\e0ab";
}
.healent-form-input-icon-53:before {
  content: "\e0ac";
}
.healent-form-input-icon-54:before {
  content: "\e0ad";
}
.healent-group-4555:before {
  content: "\e0ae";
}
.healent-path-3560:before {
  content: "\e0af";
}
.healent-path-3561:before {
  content: "\e0b0";
}
.healent-icon-5:before {
  content: "\e0b1";
}
.healent-group-4610:before {
  content: "\e0b2";
}
.healent-path-3620:before {
  content: "\e0b3";
}
.healent-path-3621:before {
  content: "\e0b4";
}
.healent-path-3622:before {
  content: "\e0b5";
}
.healent-path-3623:before {
  content: "\e0b6";
}
.healent-path-3624:before {
  content: "\e0b7";
}
.healent-path-3625:before {
  content: "\e0b8";
}
.healent-path-3626:before {
  content: "\e0b9";
}
.healent-path-3627:before {
  content: "\e0ba";
}
.healent-path-3629:before {
  content: "\e0bb";
}
.healent-path-3630:before {
  content: "\e0bc";
}
.healent-path-3631:before {
  content: "\e0bd";
}
.healent-path-3632:before {
  content: "\e0be";
}
.healent-path-3633:before {
  content: "\e0bf";
}
.healent-path-3637:before {
  content: "\e0c0";
}
.healent-pending:before {
  content: "\e0c1";
}
.healent-action-icon:before {
  content: "\e0c2";
}
.healent-action-icon-1:before {
  content: "\e0c3";
}
.healent-chart-check-action:before {
  content: "\e0c4";
}
.healent-chart-check-action-1:before {
  content: "\e0c5";
}
.healent-path-3656:before {
  content: "\e0c6";
}
.healent-group-2903:before {
  content: "\e0c7";
}
.healent-group-2904:before {
  content: "\e0c8";
}
.healent-path-3652:before {
  content: "\e0c9";
}
.healent-path-3656-1:before {
  content: "\e0ca";
}
.healent-path-3658:before {
  content: "\e0cb";
}
.healent-path-3657:before {
  content: "\e0cc";
}
.healent-maximize:before {
  content: "\e0cd";
}
.healent-minimize:before {
  content: "\e0ce";
}
.healent-minimize-1:before {
  content: "\e0cf";
}
.healent-maximize-1:before {
  content: "\e0d0";
}
.healent-frequency:before {
  content: "\e0d1";
}
.healent-component-106-1:before {
  content: "\e0d2";
}
.healent-component-105-1:before {
  content: "\e0d3";
}
.healent-component-104-1:before {
  content: "\e0d4";
}
.healent-component-103-1:before {
  content: "\e0d5";
}
.healent-component-102-1:before {
  content: "\e0d6";
}
.healent-component-100-1:before {
  content: "\e0d7";
}
.healent-component-99-1:before {
  content: "\e0d8";
}
.healent-component-98-1:before {
  content: "\e0d9";
}
.healent-group-4525:before {
  content: "\e0da";
}
.healent-group-2917:before {
  content: "\e0db";
}
.healent-alert:before {
  content: "\e0dc";
}
.healent-dropdown:before {
  content: "\e0dd";
}
.healent-custom-filer:before {
  content: "\e0de";
}
.healent-delete:before {
  content: "\e0df";
}
.healent-down-1:before {
  content: "\e0e0";
}
.healent-insurance:before {
  content: "\e0e1";
}
.healent-save:before {
  content: "\e0e2";
}
.healent-status:before {
  content: "\e0e3";
}
.healent-tag-1:before {
  content: "\e0e4";
}
.healent-provider:before {
  content: "\e0e5";
}
.healent-component-64-2:before {
  content: "\e0e6";
}
.healent-component-65-2:before {
  content: "\e0e7";
}
.healent-form-input-icon-24-2:before {
  content: "\e0e8";
}
.healent-path-4551:before {
  content: "\e0e9";
}
.healent-group-4692:before {
  content: "\e0ea";
}
.healent-path-3680:before {
  content: "\e0eb";
}
.healent-call-1:before {
  content: "\e0ec";
}
.healent-age:before {
  content: "\e0ed";
}
.healent-call:before {
  content: "\e0ee";
}
.healent-ignore:before {
  content: "\e0ef";
}
.healent-message:before {
  content: "\e0f0";
}
.healent-new-task:before {
  content: "\e0f1";
}
.healent-last-successful-call:before {
  content: "\e0f2";
}
.healent-reading-days:before {
  content: "\e0f3";
}
.healent-topic:before {
  content: "\e0f4";
}
.healent-priority:before {
  content: "\e0f5";
}

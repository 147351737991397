/*
"@angular/material/prebuilt-themes/indigo-pink.css",
https://levelup.gitconnected.com/defining-your-own-theme-in-angular-material-8a4a6ffad400
https://m1.material.io/style/color.html#
*/
@use '@angular/material' as mat;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');
@include mat.core();
* {
  margin: 0;
  padding: 0;
}
body {
  --accent: #2b5adc;
  --light-accent: #2b5adc14;
  --primary: #152145;
  --dangerous: #ee4266;
  --light-dangerous: #ee426608;
  --safe: #1b998b;
  --warning: #f4d35e;
  --warning-shade-1: #f58349;
  --secondary_shade_10: #ffffff;
  --secondary_shade_20: #f7f8fc;
  --secondary_shade_30: #eeeff5;
  --secondary_shade_40: #d6d7dd;
  --secondary_shade_50: #a1a3b3;
}

.healent {
  --accent: #2b5adc;
  --light-accent: #2b5adc14;
  --primary: #152145;
  --dangerous: #ee4266;
  --light-dangerous: #ee426608;
  --safe: #1b998b;
  --warning: #f4d35e;
  --warning-shade-1: #f58349;
  --secondary_shade_10: #ffffff;
  --secondary_shade_20: #f7f8fc;
  --secondary_shade_30: #eeeff5;
  --secondary_shade_40: #d6d7dd;
  --secondary_shade_50: #a1a3b3;
}
.cps {
  --accent: #d1a652;
  --light-accent: #d1a65214;
  --primary: #041d26;
  --dangerous: #e21c1c;
  --light-dangerous: #e21c1c08;
  --safe: #00a874;
  --warning: #e2ba1c;
  --warning-shade-1: #e27f1c;
  --secondary_shade_10: #ffffff;
  --secondary_shade_20: #f5f9f9;
  --secondary_shade_30: #e2eaf0;
  --secondary_shade_40: #c5d2db;
  --secondary_shade_50: #adc0cc;
}
.akps {
  --accent: #38a3c7;
  --light-accent: #38a3c714;
  --primary: #000c2a;
  --dangerous: #e2211c;
  --light-dangerous: #e2211c08;
  --safe: #1baf2f;
  --warning: #e2ba1c;
  --warning-shade-1: #e27f1c;
  --secondary_shade_10: #ffffff;
  --secondary_shade_20: #faf7f2;
  --secondary_shade_30: #f2ebe1;
  --secondary_shade_40: #cfcbc4;
  --secondary_shade_50: #adaaa8;
}

$accent: var(--accent);
$lightAccent: var(--light-accent);
$primary: var(--primary);
$dangerous: var(--dangerous);
$lightDangerous: var(--light-dangerous);
$safe: var(--safe);
$warning: var(--warning);
$warning-shade-1: var(--warning-shade-1);
$secondary_shade_10: var(--secondary_shade_10);
$secondary_shade_20: var(--secondary_shade_20);
$secondary_shade_30: var(--secondary_shade_30);
$secondary_shade_40: var(--secondary_shade_40);
$secondary_shade_50: var(--secondary_shade_50);

$fontConfig: (
  display-4:
    mat.m2-define-typography-level(112px, 112px, 300, 'Roboto', -0.0134em),
  display-3:
    mat.m2-define-typography-level(56px, 56px, 400, 'Roboto', -0.0089em),
  display-2: mat.m2-define-typography-level(48px, 48px, 400, 'Roboto', 0em),
  display-1: mat.m2-define-typography-level(32px, 40px, 400, 'Roboto', 0.0074em),
  headline: mat.m2-define-typography-level(32px, 32px, 400, 'Roboto', 0em),
  title: mat.m2-define-typography-level(24px, 32px, 500, 'Roboto', 0.0075em),
  subheading-2:
    mat.m2-define-typography-level(18px, 28px, 400, 'Roboto', 0.0094em),
  subheading-1:
    mat.m2-define-typography-level(16px, 24px, 500, 'Roboto', 0.0067em),
  body-2: mat.m2-define-typography-level(16px, 24px, 500, 'Roboto', 0.0179em),
  body-1: mat.m2-define-typography-level(16px, 20px, 400, 'Roboto', 0.0179em),
  button: mat.m2-define-typography-level(16px, 14px, 500, 'Roboto', 0.0893em),
  caption: mat.m2-define-typography-level(14px, 20px, 400, 'Roboto', 0.0333em),
  input: mat.m2-define-typography-level(inherit, 1.125, 400, 'Roboto', 1.5px),
  h1: mat.m2-define-typography-level(48px, 48px, 400, 'Roboto', 0em),
  h2: mat.m2-define-typography-level(32px, 32px, 400, 'Roboto', 0em),
  h3: mat.m2-define-typography-level(24px, 26px, 400, 'Roboto', 0em),
  h4: mat.m2-define-typography-level(20px, 23px, 400, 'Roboto', 0em),
  h5: mat.m2-define-typography-level(16px, 18px, 400, 'Roboto', 0em),
  h6: mat.m2-define-typography-level(14px, 16px, 400, 'Roboto', 0em)
);

// Define the primary palette
$primary-palette: (
  50: $primary,
  100: $primary,
  200: $primary,
  300: $primary,
  400: $primary,
  500: $primary,
  600: $primary,
  700: $primary,
  800: $primary,
  900: $primary,
  A100: $primary,
  A200: $primary,
  A400: $primary,
  A700: $primary,
  contrast: (
    50: $secondary_shade_10,
    100: $secondary_shade_10,
    200: $secondary_shade_10,
    300: $secondary_shade_10,
    400: $secondary_shade_10,
    500: $secondary_shade_10,
    600: $secondary_shade_10,
    700: $secondary_shade_10,
    800: $secondary_shade_10,
    900: $secondary_shade_10,
    A100: $secondary_shade_10,
    A200: $secondary_shade_10,
    A400: $secondary_shade_10,
    A700: $secondary_shade_10
  )
);
// Define the accent palette using your custom $accent color
$accent-palette: (
  50: $accent,
  100: $accent,
  200: $accent,
  300: $accent,
  400: $accent,
  500: $accent,
  600: $accent,
  700: $accent,
  800: $accent,
  900: $accent,
  A100: $accent,
  A200: $accent,
  A400: $accent,
  A700: $accent,
  contrast: (
    50: $secondary_shade_10,
    100: $secondary_shade_10,
    200: $secondary_shade_10,
    300: $secondary_shade_10,
    400: $secondary_shade_10,
    500: $secondary_shade_10,
    600: $secondary_shade_10,
    700: $secondary_shade_10,
    800: $secondary_shade_10,
    900: $secondary_shade_10,
    A100: $secondary_shade_10,
    A200: $secondary_shade_10,
    A400: $secondary_shade_10,
    A700: $secondary_shade_10
  )
);

// Define the warn palette
$warn-palette: (
  50: $warning,
  100: $warning,
  200: $dangerous,
  300: $dangerous,
  400: $warning,
  500: $dangerous,
  600: $warning,
  700: $dangerous,
  800: $warning,
  900: $dangerous,
  A100: $warning,
  A200: $dangerous,
  A400: $warning,
  A700: $dangerous,
  contrast: (
    50: $secondary_shade_10,
    100: $secondary_shade_10,
    200: $secondary_shade_10,
    300: $secondary_shade_10,
    400: $secondary_shade_10,
    500: $secondary_shade_10,
    600: $secondary_shade_10,
    700: $secondary_shade_10,
    800: $secondary_shade_10,
    900: $secondary_shade_10,
    A100: $secondary_shade_10,
    A200: $secondary_shade_10,
    A400: $secondary_shade_10,
    A700: $secondary_shade_10
  )
);
$primary-palette: mat.m2-define-palette($primary-palette);
$accent-palette: mat.m2-define-palette($accent-palette);
$warn-palette: mat.m2-define-palette($warn-palette);

// Define the theme
$Healent: mat.m2-define-light-theme(
  (
    color: (
      primary: $primary-palette,
      accent: $accent-palette,
      warn: $warn-palette,
      background: $secondary_shade_20,
      secondary_shade_50: $secondary_shade_50,
      secondary_shade_40: $secondary_shade_40,
      secondary_shade_30: $secondary_shade_30,
      secondary_shade_20: $secondary_shade_20,
      secondary_shade_10: $secondary_shade_10
    ),
    typography: $fontConfig
  )
);

@include mat.all-component-themes($Healent);

// Define classes colors
$colors: (
  'primary': $primary,
  'accent': $accent,
  'dangerous': $dangerous,
  'safe': $safe,
  'warning': $warning,
  'warning-shade-1': $warning-shade-1,
  'secondary-shade-50': $secondary_shade_50,
  'secondary-shade-40': $secondary_shade_40,
  'secondary-shade-30': $secondary_shade_30,
  'secondary-shade-20': $secondary_shade_20,
  'secondary-shade-10': $secondary_shade_10
);

// Iterate through the colors and generate text color classes
@each $name, $color in $colors {
  .text-#{$name} {
    color: $color;
  }
}

// Iterate through the colors and generate background color classes
@each $name, $color in $colors {
  .bg-#{$name} {
    background-color: $color;
  }
}

// drawer widths

$drawer-small-width: 40%;
$drawer-medium-width: 50%;
$drawer-large-width: 90%;

// Define sizes
$sizes: (
  'xxl': 120px,
  'xl': 60px,
  'l': 30px,
  'm': 20px,
  'xm': 15px,
  's': 10px,
  'xs': 5px,
  'xxs': 2px
);

// Mixin for generating margin and padding classes
@mixin generate-utility-classes($property, $directions) {
  @each $name, $size in $sizes {
    @each $dir in $directions {
      .#{$property}-#{$dir}-#{$name} {
        #{$property}-#{$dir}: $size !important;
      }
    }
    // Generate classes for all sides if needed
    .#{$property}-#{$name} {
      #{$property}: $size !important;
    }
  }
  // Generate classes for zero value
  .#{$property}-0 {
    #{$property}: 0 !important;
  }
  @each $dir in $directions {
    .#{$property}-#{$dir}-0 {
      #{$property}-#{$dir}: 0 !important;
    }
  }
}

// Directions shorthand
$all-directions: (top, right, bottom, left);
$margin-padding-directions: (top, right, bottom, left, '');

// Generate margin and padding utility classes
@include generate-utility-classes(margin, $margin-padding-directions);
@include generate-utility-classes(padding, $margin-padding-directions);

body.akps .mat-toolbar.mat-accent {
  --mat-toolbar-container-background-color: var(--primary) !important;
}

// flex class
.flex {
  display: flex;
}
.row {
  flex-direction: row;
}
.column {
  flex-direction: column;
}
.space-between {
  justify-content: space-between;
}

// Generate styles
body {
  background-color: $secondary_shade_20;
}
a {
  color: $accent;
}
.curser-pointer {
  cursor: pointer;
}
.hide {
  display: none !important;
}
.nowrap {
  white-space: nowrap !important;
}
.mat-mdc-progress-bar.mat-accent {
  --mdc-linear-progress-active-indicator-color: var(
    --secondary_shade_30
  ) !important;
}
// Toolbar
.mat-toolbar {
  padding: 0 map-get($sizes, l) !important;
}
// Cards styles
.padding-0 {
  .mdc-card {
    .mat-mdc-card-content {
      padding: 0;
    }
  }
}
.mat-mdc-raised-button.mat-accent {
  --mdc-protected-button-container-color: var(--accent);
  --mdc-protected-button-label-text-color: var(--secondary_shade_10);
  --mat-protected-button-state-layer-color: var(--secondary_shade_10);
  --mat-protected-button-ripple-color: rgba(0, 0, 0, 0.1);
}
.h-main .mat-drawer-side {
  border-right-width: 0;
}
.h-main mat-nav-list a {
  cursor: pointer;
}
.h-accent {
  color: var(--accent);
}
.h-primary {
  color: var(--primary);
}
// Generate table styles
.table-container {
  position: relative;
  table {
    thead {
      position: sticky;
      top: 0;
      background: $secondary_shade_10;
      z-index: 2;
      th {
        white-space: nowrap;
      }
      td {
        white-space: pre-line;
        .mdc-button__label {
          white-space: nowrap;
        }
      }
    }
  }
  &::-webkit-scrollbar-track {
    -webkit-box-shadow: none;
    background-color: $secondary_shade_30;
  }

  &::-webkit-scrollbar {
    width: 5px;
    background-color: transparent;
    height: 14px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $primary;
    border: 1px solid $primary;
  }
}
.container-full {
  display: flex;
  width: 100%;
  height: calc(100svh - 64px);
}
.flex-1 {
  flex: 1;
}
.height-100 {
  height: 100%;
}
.width-100 {
  width: 100%;
}
.overflow-auto {
  overflow: auto;
}
.overflow-hidden {
  overflow: hidden;
}
.table-btn-icon {
  padding-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    display: flex;
    font-size: 24px;
    line-height: 1;
    color: var(--secondary_shade_50);
  }
}

// Forms and inputs styles
.mat-mdc-form-field-subscript-wrapper,
.mat-mdc-form-field-bottom-align::before {
  height: 0;
}

.flag-icon {
  border-radius: 50%;
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-16 {
  height: 20px !important;
  width: 20px !important;
  font-size: 20px;
}
.priority-critical {
  background-color: $dangerous;
}

.priority-high {
  background-color: $warning_shade_1;
}

.priority-normal {
  background-color: $secondary_shade_50;
}
.hide {
  display: none !important;
}
.hide-date {
  width: 0 !important;
}
.hide-date > mat-form-field > div {
  height: 0 !important;
}

/****************************************************************/
/****************************************************************/
/****************************************************************/
/****************************************************************/
/****************************************************************/
/****************************************************************/

.main-container {
  height: calc(100vh - 60px);
  overflow: hidden;
  padding: 30px;
  background: var(--secondary_shade_20);
  display: flex;
  flex-direction: column;
}
.main-header {
  flex-shrink: 0;
  height: 30px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
  .header-right {
    display: flex;
    gap: 20px;
  }
  h1 {
    font-size: 24px;
    line-height: 1;
    font-family: 'Roboto';
    font-weight: bold;
    color: var(--primary);
  }
  .mat-mdc-form-field-type-mat-select:not(.mat-form-field-disabled)
    .mat-mdc-text-field-wrapper {
    background: var(--secondary_shade_10);
  }
}

.filter-card {
  background-color: var(--secondary_shade_10);
  height: 50px;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: medium;
  button {
    font-size: 16px;
    font-weight: medium;
  }
}

.main-body {
  // height: calc(100vh - 172px);
  display: flex;
  flex-direction: column;
  // height: 100%;
  width: 100%;
  flex-grow: 1; /* Take up remaining space dynamically */
  flex-shrink: 1; /* Shrink if necessary */
  flex-basis: 0;
  min-height: 0;
  .mat-mdc-header-row {
    background: var(--secondary_shade_30);
    color: var(--primary);
  }
  .mat-mdc-cell {
    border-bottom-color: var(--secondary_shade_50);
    text-transform: capitalize;
  }
  .mat-mdc-table {
    border-bottom-color: var(--secondary_shade_50);
    border-bottom-width: 0;
    border-bottom-style: solid;
  }
  .mat-expansion-panel-header-title {
    text-wrap: nowrap;
    flex-grow: 0;
    font-size: 20px;
    font-weight: 500;
    margin-right: 20px;
  }
  .pre-registered {
    .mat-expansion-panel-header-title {
      color: var(--dangerous);
    }
  }
  .in-progress {
    .mat-expansion-panel-header-title {
      color: var(--warning-shade-1);
    }
  }
  .checked-in {
    .mat-expansion-panel-header-title {
      color: var(--accent);
    }
  }
  .bg-primary {
    background: var(--dangerous);
    color: var(--secondary_shade_10);
  }

  .bg-warning {
    background: var(--warning-shade-1);
    color: var(--secondary_shade_10);
  }
  .bg-accent {
    background: var(--accent);
    color: var(--secondary_shade_10);
  }
  .bg-black {
    background: var(--primary);
    color: var(--secondary_shade_10);
  }
  .panel-description {
    gap: 20px;
    span {
      padding: 5px 15px;
      border-radius: 30px;
    }
  }
  .mat-sort-header-content {
    font-size: 16px;
    line-height: 1;
    font-family: 'Roboto';
    font-weight: bold;
    text-wrap: nowrap;
  }

  th.mat-column-Delete .mat-sort-header-content {
    width: 120px;
  }

  th.mat-column-paid .mat-sort-header-content {
    width: 80px;
  }

  th.mat-column-copay .mat-sort-header-content {
    width: 80px;
  }

  th.mat-column-balance .mat-sort-header-content {
    width: 80px;
  }

  th.mat-column-pre .mat-sort-header-content {
    width: 30px;
  }
  th.mat-column-date_of_birth .mat-sort-header-content {
    width: 120px;
  }
}
.mat-mdc-form-field-icon-suffix {
  padding: 0 0 0 24px !important;
}

app-custom-location-select {
  .select-input,
  .mat-mdc-select-value-text {
    color: var(--primary);
  }
  .mat-mdc-select-value {
    color: var(--secondary_shade_10);
  }
  &:hover .mat-mdc-select-value {
    color: var(--secondary_shade_10);
  }

  .mat-mdc-text-field-wrapper {
    height: 30px;
    padding: 0;
  }
  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    min-height: 30px;
    max-width: 100%;
    width: 100%;
  }
  .mat-mdc-option .mdc-list-item__primary-text {
    text-wrap: nowrap;
  }
  .mat-mdc-select {
    max-width: 250px;
    width: 100%;
  }
  .mat-mdc-select .mat-select-value-text {
    color: var(--primary) !important;
  }
}
.mat-drawer-container {
  position: inherit !important;
}
.main-body .mat-expansion-panel-body {
  padding: 0 !important;
}
.main-body .mat-expansion-panel:not([class*='mat-elevation-z']) {
  box-shadow: none;
}
.main-body .mat-expansion-panel-spacing {
  margin: 0;
}
.main-body .mat-expansion-indicator {
  pointer-events: auto;
  transform: rotate(0deg) !important;
}
.search-input {
  border-bottom: 1px solid var(--secondary_shade_50);
  .mat-mdc-form-field-icon-prefix,
  .mat-mdc-form-field-icon-suffix {
    align-self: flex-start;
    line-height: 1;
  }

  .mdc-line-ripple {
    display: none !important;
  }
  .healent-search {
    color: var(--secondary_shade_50);
  }
  .mat-mdc-form-field-infix {
    width: 340px;
    height: 29px;
    align-items: flex-start;
    display: flex;
  }
  .mat-mdc-form-field-flex {
    height: 30px;
    align-items: flex-start;
    display: flex;
  }
  .mdc-text-field--outlined .mdc-notched-outline {
    height: 29px;
  }
  .mdc-text-field--outlined .mat-mdc-form-field-infix,
  .mdc-text-field--no-label .mat-mdc-form-field-infix {
    padding: 0;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled) {
    background-color: transparent;
  }
  .mdc-text-field {
    padding-right: 0;
  }
  &.mat-mdc-form-field {
    height: 29px;
  }
  &.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay,
  .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
    display: none;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-text-field__input::placeholder {
    color: var(--secondary_shade_50);
  }
  .btn-close {
    color: var(--dangerous);
    font-size: 20px;
    font-weight: bold;
    cursor: pointer;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-text-field__input {
    color: var(--primary);
    font-size: 16px;
    font-weight: 500;
    font-family: 'Roboto';
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    border-bottom-color: var(--secondary_shade_50) !important;
    border-bottom-width: 1px;
  }
  .mdc-line-ripple::before,
  .mdc-line-ripple::after {
    bottom: 0;
  }

  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    border-bottom-color: var(--secondary_shade_50) !important;
    border-bottom-width: 1px;
  }
}
.location-select-container {
  border-radius: 5px !important;
  overflow: hidden;
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::before {
    display: none;
  }
  .mat-mdc-form-field:not(.mat-form-field-no-animations)
    .mdc-line-ripple::after {
    display: none;
  }
  .mdc-text-field--filled:not(.mdc-text-field--disabled)
    .mdc-line-ripple::after {
    display: none;
  }
  .mat-mdc-select-placeholder {
    color: var(--primary);
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
  }
  &.mat-mdc-form-field.mat-focused {
    .mat-mdc-form-field-focus-overlay {
      opacity: 1;
      background: var(--accent);
      color: var(--secondary_shade_10);
    }
    .select-icon,
    .mat-mdc-select {
      color: var(--secondary_shade_10);
    }
    .mat-mdc-select-value-text {
      color: var(--secondary_shade_10) !important;
    }
    .mat-mdc-select-value-text {
      text-overflow: unset !important;
    }
  }
  .mat-mdc-text-field-wrapper:hover {
    .mat-mdc-form-field-focus-overlay {
      opacity: 1;
      background: var(--accent);
    }
    .mat-mdc-select-placeholder,
    .select-icon {
      color: var(--secondary_shade_10);
    }
    .mat-mdc-select-value-text {
      color: var(--secondary_shade_10);
    }
    .select-icon,
    .mat-mdc-select {
      color: var(--secondary_shade_10) !important;
    }
  }
  .mat-mdc-select-placeholder {
    transition: none;
  }
  &.mat-form-field-appearance-fill
    .mdc-text-field--no-label
    .mat-mdc-select-arrow-wrapper {
    display: none;
  }
  &.mat-focused .mat-mdc-text-field-wrapper .mat-mdc-select-placeholder,
  &.mat-focused .mat-mdc-form-field-icon-prefix > .mat-icon {
    color: var(--secondary_shade_10) !important;
  }
  &.mat-focused .mat-mdc-form-field-icon-prefix > .mat-icon {
    font-size: 20px;
  }
  &:hover .mat-mdc-text-field-wrapper > .select-icon {
    color: var(--secondary_shade_10) !important;
  }
  &:hover .mat-mdc-text-field-wrapper > .select-icon .mat-select-value-text {
    color: var(--secondary_shade_10) !important;
  }
  & .mdc-text-field--focused > .select-icon .mat-select-value-text {
    color: var(--secondary_shade_10) !important;
  }

  .select-icon {
    line-height: 1;
    padding-right: 10px;
    font-size: 16px;
    color: var(--primary);
  }
}
.mat-drawer-transition .mat-drawer-content {
  transition-duration: 100ms !important;
  transition-timing-function: ease-out !important;
}
.drawer-container {
  mat-drawer {
    width: 30vw;
    padding: 30px;
  }
  .drawer-header {
    display: flex;
    justify-content: space-between;
    padding-bottom: 30px;
    border-bottom: 1px solid var(--secondary_shade_50);
    .drawer-title {
      font-size: 24px;
      line-height: 1;
      font-family: 'Roboto';
      font-weight: bold;
      color: var(--primary);
    }
    i {
      font-size: 24px;
      color: var(--secondary_shade_50);
      cursor: pointer;
    }
  }
}
.mdc-switch__ripple {
  display: none !important;
}
.mdc-switch {
  width: 40px !important;
  height: 20px !important;
}
.main-body .mdc-switch__track {
  overflow: hidden;
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 30px;
  padding: 0;
}

.mdc-switch__track::before,
.mdc-switch__track::after {
  border: 1px solid var(--secondary_shade_50) !important;
  content: '';
  height: 100%;
  left: 0;
  position: absolute;
  width: 100%;
  border-width: 1px !important;
  border-color: var(--secondary_shade_50) !important;
}
.mdc-switch__handle::before,
.mdc-switch__handle::after {
  left: 2px !important;
}
.mat-mdc-slide-toggle .mdc-switch__handle:has(.mdc-switch__icons) {
  width: 16px !important;
  height: 16px !important;
}
.mdc-switch__icon {
  display: none !important;
}
.mdc-switch--selected:enabled .mdc-switch__handle::after,
.mdc-switch--selected:enabled .mdc-switch__handle::before {
  background: var(--secondary_shade_10) !important;
  left: 5px !important;
}
.mdc-switch--unselected:enabled .mdc-switch__handle::after {
  background: var(--secondary_shade_50) !important;
}
.mat-internal-form-field > label {
  padding-left: 15px !important;
  font-size: 16px !important;
  font-family: 'Roboto';
  font-weight: 500 !important;
  color: var(--primary);
}

.mdc-switch__track {
  width: 40px !important;
  height: 20px !important;
  border-radius: 30px !important;
}
.mdc-switch__handle-track {
  height: 16px !important;
  width: 16px !important;
  top: 2px !important;
}
.mdc-switch__handle::before,
.mdc-switch__handle::after {
  border: 0 !important;
}
.mdc-switch__track::before {
  background: var(--secondary_shade_10) !important;
}
.mat-mdc-form-field-icon-prefix {
  padding: 0px 10px 0 0 !important;
}
.mat-expansion-indicator.ng-trigger.ng-trigger-indicatorRotate {
  svg {
    display: none !important;
  }
  &::after {
    font-family: 'healent';
    content: '\e09a';
    border: 0 !important;
    transform: none !important;
    font-size: 20px;
    color: var(--primary);
    cursor: pointer;
  }
}
.mat-expansion-panel-header.mat-expanded
  .mat-expansion-indicator.ng-trigger.ng-trigger-indicatorRotate::after {
  font-family: 'healent';
  content: '\e09b';
  border: 0 !important;
  transform: none !important;
  font-size: 20px;
  color: var(--secondary_shade_50);
  cursor: pointer;
}
.mat-expansion-indicator::after {
  padding: 0 !important;
}
.healent-gear-fill {
  cursor: pointer;
}
.healent-gear-fill-gray {
  color: var(--secondary_shade_50);
}
.cdk-overlay-pane {
  width: max-content !important;
  margin-top: 10px;
  min-width: 200px;
  div.mat-mdc-select-panel {
    padding: 0;
  }
  .mat-mdc-option.mat-mdc-option-active.mdc-list-item {
    background-color: var(--secondary_shade_10) !important;
    span {
      color: var(--primary);
    }
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
      color: var(--primary);
    }
  }
  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    background-color: var(--accent) !important;
    span {
      color: var(--secondary_shade_10);
    }
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-checked::after,
    .mat-pseudo-checkbox-minimal.mat-pseudo-checkbox-indeterminate::after {
      color: var(--secondary_shade_10);
    }
  }
  .mat-mdc-option.mdc-list-item {
    border-bottom: 1px solid var(--secondary_shade_50);
    span {
      font-size: 16px;
      font-family: 'Roboto';
      font-weight: bold;
    }
  }
  .mat-mdc-option.mdc-list-item span {
    color: var(--primary);
  }
}
.mat-mdc-select {
  color: var(--primary) !important;
}

table {
  td {
    font-size: 16px;
    font-family: 'Roboto';
    font-weight: 500;
    color: var(--primary);
    mat-form-field {
      height: 30px;
      width: 120px;
      overflow: hidden;
      border-radius: 30px;
      cursor: pointer;
      .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: var(--secondary_shade_50);
      }
      &.mat-focused .mdc-text-field--filled:not(.mdc-text-field--disabled) {
        background-color: var(--accent);
      }
      .mat-mdc-text-field-wrapper:hover .mat-mdc-form-field-focus-overlay {
        opacity: 1;
      }
      .mat-mdc-form-field-focus-overlay {
        background-color: var(--accent);
      }

      .mdc-text-field--outlined .mat-mdc-form-field-infix,
      .mdc-text-field--no-label .mat-mdc-form-field-infix {
        padding: 0;
      }
      .mat-mdc-select,
      .mat-mdc-select-trigger {
        color: var(--secondary_shade_10);
        height: 30px;
      }
      .mat-mdc-select-placeholder {
        color: var(--secondary_shade_10);
      }
      .mat-mdc-select-arrow > svg {
        display: none;
      }
      .mat-mdc-select-arrow {
        transform: rotate(90deg);
        width: 16px;
        height: 30px;
      }
      .mat-mdc-select-arrow::after {
        content: '\2b';
        font-family: 'healent';
        color: var(--secondary_shade_10);
        font-size: 16px;
        width: 16px;
        height: 30px;
      }
      .mdc-line-ripple {
        display: none;
      }
    }
  }
  .mdc-checkbox {
    display: inline-block;
    position: relative;
    flex: 1;
    box-sizing: content-box;
    width: 25px;
    height: 25px;
    line-height: 0;
    white-space: nowrap;
    cursor: pointer;
    vertical-align: bottom;
    padding: 0;
    margin: 0;
  }
  .mat-mdc-checkbox .mat-internal-form-field {
    color: var(--accent);
    border-color: var(--secondary_shade_50);
    border-radius: 5px;
    border: 1px solid var(--secondary_shade_50);
  }
  .mdc-checkbox .mdc-checkbox__native-control {
    width: 25px;
    height: 25px;
  }
  .mdc-checkbox .mdc-checkbox__background {
    border-color: var(--secondary_shade_50);
    background-color: var(--secondary_shade_10);
    width: 25px;
    height: 25px;
    top: 0;
    left: 0;
    border-radius: 5px;
    border: 0;
  }
  .mdc-checkbox__checkmark,
  .mdc-checkbox__native-control:indeterminate
    ~ .mdc-checkbox__background
    .mdc-checkbox__mixedmark {
    transform: scale(0.7) !important;
  }
  .mat-mdc-row,
  .mdc-data-table__content {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-family: 'Roboto' !important;
    line-height: 1 !important;
    font-size: 16px !important;
    font-weight: 500 !important;
  }
}
.no-entries {
  span {
    color: var(--secondary_shade_50);
    font-size: 20px;
    font-weight: 500;
    padding: 30px 0;
    display: block;
    width: 100%;
  }
}
@media screen and (max-width: 1024px) {
  .main-header {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .header-left,
  .header-right {
    width: 100%;
  }

  .header-right {
    margin-bottom: 30px;
  }
  .main-body {
    height: calc(100vh - 132px);
    margin-top: 60px;
  }
  .m-d-n {
    display: none !important;
  }
}
.reg_status-icon {
  line-height: 1;
  position: relative;
  text-align: center;
  i {
    font-size: 16px;
    padding-right: 10px;
    cursor: pointer;
  }
  .h-red.healent-q-5::before {
    color: var(--dangerous);
  }
  .h-warning.healent-path-3623::before {
    color: var(--warning-shade-1);
  }
  .h-accent.healent-no-issues::before {
    color: var(--accent);
  }
  .healent-path-3620::before {
    color: var(--secondary_shade_50);
  }
  .healent-path-3630::before {
    color: var(--warning-shade-1);
  }
  .healent-bell::before {
    color: var(--warning-shade-1);
  }
  .healent-path-3631::before {
    color: var(--dangerous);
  }
  .healent-path-3629::before {
    color: var(--dangerous);
  }
  .healent-icon-5::before {
    color: var(--dangerous);
  }
  .healent-path-3625::before {
    color: var(--secondary_shade_50);
  }
  .healent-path-3626::before {
    color: var(--secondary_shade_50);
  }
  .healent-path-3627::before {
    color: var(--secondary_shade_50);
  }
  .healent-action-icon::before {
    color: var(--secondary_shade_50);
  }
  .healent-action-icon-1::before {
    color: var(--secondary_shade_50);
  }
  .healent-chart-check-action-1::before {
    color: var(--safe);
  }
  .healent-chart-check-action::before {
    color: var(--dangerous);
  }
  .healent-pending::before {
    color: var(--secondary_shade_50);
  }
  .icon-patient-count::before {
    color: var(--warning-shade-1);
  }
  .icon-visit-count::before {
    color: var(--dangerous);
  }
  .healent-form-input-icon-26 {
    color: var(--primary);
  }
  .healent-path-3633 {
    color: var(--warning-shade-1);
  }
  .random-orange {
    color: var(--warning-shade-1) !important
  }
  .random-green {
    color: var(--safe) !important;
  }
  .random-black {
    color: var(--primary) !important
  }
  .random-grey {
    color: var(--secondary_shade_50) !important
  }
}
app-login {
  .mat-mdc-card-content {
    display: flex;
    padding: 0 16px;
    flex-direction: column;
    gap: 15px;
  }
}
.mat-mdc-raised-button[disabled],
.mat-mdc-raised-button.mat-mdc-button-disabled,
.mat-mdc-raised-button.mat-accent {
  height: 56px;
}
.h-main .mat-mdc-form-field-error {
  display: block;
  color: var(--dangerous);
  text-align: center;
  line-height: 1.4;
  font-weight: 500;
}

.th-justify-content-center > div {
  justify-content: center;
}
table {
  td.td-text-center {
    text-align: center;
  }
}
.w2 {
  width: 2%;
}
.w3 {
  width: 3%;
}
.w5 {
  width: 5%;
}
.w10 {
  width: 10%;
}
.w15 {
  width: 15%;
}
.w20 {
  width: 20%;
}
.w25 {
  width: 25%;
}
.w30 {
  width: 30%;
}
.td-appointment-type {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  gap: 10px;
  i {
    font-size: 20px;
  }
}
.users.table-wrapper {
  height: calc(100vh - 122px);
  overflow: auto;
}
.users.table-wrapper table {
  padding-bottom: 56px;
}
.users.table-wrapper .cdk-header-row {
  position: sticky;
  top: 0;
  z-index: 1;
}
.users.table-wrapper mat-paginator {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.search-input {
  width: 100%;
}

.healent-search {
  cursor: pointer;
  margin-top: 2px;
  display: block;
  font-size: 20px;
}
.btn-close {
  cursor: pointer;
}
.btn-hint {
  width: max-content;
  color: var(--secondary_shade_50);
  font-style: italic;
}
.btn-hint,
.btn-close {
  position: absolute;
  right: 1px;
}

.h-main .mat-drawer-content {
  overflow-x: hidden;
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(238, 239, 245, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.spinner-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}

.loading-text {
  color: var(--primary);
  font-size: 16px;
  font-weight: 500;
  margin-top: 8px;
}

.custom-spinner.mat-mdc-progress-spinne {
  height: 50px;
  width: 50px;
}

.custom-spinner.mat-mdc-progress-spinner
  .mdc-circular-progress__determinate-circle,
.custom-spinner.mat-mdc-progress-spinner
  .mdc-circular-progress__indeterminate-circle-graphic {
  stroke: var(--accent);
}

.dashboard-tooltip {
  position: absolute;
  top: -10px;
  left: 0;
}

.dashboard-tooltip .mdc-tooltip__surface {
  font-size: 14px;
  color: var(--primary);
  background-color: var(--secondary_shade_10);
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  padding: 10px;
  font-weight: 400;
}

.drawer-small {
  width: $drawer-small-width !important;
}

.drawer-medium {
  width: $drawer-medium-width !important;
}

.drawer-large {
  width: $drawer-large-width !important;
}

.blink {
  animation: blink 1s infinite;
}

@keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}

.healent-path-3621::before {
  color: var(--dangerous);
  font-size: 24px;
}
